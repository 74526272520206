<template>
    <div>
        <div v-for="position in row.item.positions"
             :key="position.id"
        >
            <div v-if="position.article_id">
                <a href="#"
                   @click="$root.$children[0].openModal('article-modal', {id: position.article_id,tab:'stock'}, refreshTable)"
                >{{ position.count }} {{ position.name }}</a>
            </div>
            <div v-else>{{ position.count }} {{ position.name }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': [Function],
    },
}
</script>